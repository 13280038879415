import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import { Colors } from '../constants'
import { Paper, Typography, Grid } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { navigate } from 'gatsby'

const ErrorImg = require('../dist/images/404.svg');

const NotFoundPage = () => (
  <Layout>
    <ErrorPageWrapper>
    <SEO title="404: Not found" />
    
      <ErrorMessageContainer>
        <Grid spacing={3} container>
            <Grid item xs={12} md={7} style={{textAlign: 'left'}}>

              <div style={{display: 'grid', gridTemplateColumns: '80% 20%'}}>
                <ErrorHeader>
                  <span>404</span>
                  Error                
                </ErrorHeader>
                <ErrorHeader
                  style={{ cursor: 'pointer', textAlign: 'right' }}
                  onClick={() => navigate('/')}
                >
                    <span style={{height: '30px', width: '30px', background: '#f0f0f0', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%'}}><ArrowBackIcon /></span>
                    
                
                </ErrorHeader>
              </div>

              <ErrorBody>You've reached a page that does not exist. Sorry about that.</ErrorBody>
            </Grid>
            <Grid item xs={12} md={5} justify={'center'} alignContent={'center'} alignItems={'center'}>
              <ErrorImage />
            </Grid>
        </Grid>
        
        
      </ErrorMessageContainer>
    </ErrorPageWrapper>

    {/* <div class="modal-overlay">
  <div class="modal">
    
    

    <div class="modal-content">
    <SEO title="404: Not found" />
    </div>
    
  </div> */}
{/* </div> */}
  </Layout>
)

export default NotFoundPage

const ErrorBody = styled(Typography)`
  font-size: calc(10px + 6 * ((100vw - 185px) / 580));
  line-height: calc(15px + 6 * ((100vw - 185px) / 580));
  margin-top: 25px;
  color: gray;
`;
const ErrorHeader = styled(Typography)`
  line-height: calc(22px + 6 * ((100vw - 185px) / 380));
  font-size: calc(22px + 6 * ((100vw - 185px) / 380));
  span {
    color: ${Colors.auxidusBlue};
    font-weight: bold;
  }
`;
const ErrorMessageContainer = styled.div`
  border-bottom: 2px solid ${Colors.auxidusBlue};
  min-width: 50vmax;
  min-height: 30vmin;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 25px 25px 25px;
`;

const ErrorPageWrapper = styled.div`
`;

const ErrorImage = styled.div`
  background: url(${ErrorImg}) no-repeat center center;
  background-size: contain;
  height: 150px;
  width: 100%;
  position: relative;
  display: block;
`;